import * as React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found | Leah Jordain" />
    <hr className={styles.redhr}></hr>
    <h1>404: Not Found</h1>
    <div className={styles.content}>
    <p>Much like the extra space on my bookshelves, this page does not exist. Try one of the links above, or <Link to="../contact">contact me</Link> if you think this is an error!</p>
    </div>
    {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
  </Layout>
)

export default NotFoundPage
